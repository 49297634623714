import cn from 'classnames';
import React from 'react';

import IconChevronLeft from 'editor/src/component/Icon/IconChevronLeft';
import IconChevronRight from 'editor/src/component/Icon/IconChevronRight';

import styles from './index.module.scss';

interface Props {
  name: string;
  isPrevAvailable: boolean;
  isNextAvailable: boolean;
  onPrevClick: () => void;
  onNextClick: () => void;
}

function SpreadPreviewNavigation({ name, isPrevAvailable, isNextAvailable, onPrevClick, onNextClick }: Props) {
  return (
    <div className={cn(styles.container, 'cy-single-flat-preview-navigation')}>
      <button
        onClick={onPrevClick}
        className={cn(styles.button, 'cy-single-preview-prev-nav-button', {
          [styles.nonClickable]: !isPrevAvailable,
          'cy-disabled': !isPrevAvailable,
        })}
        aria-label="Previous"
      >
        <IconChevronLeft />
      </button>
      <span className={cn(styles.name)}>{name}</span>
      <button
        onClick={onNextClick}
        className={cn(styles.button, 'cy-single-preview-next-nav-button', {
          [styles.nonClickable]: !isNextAvailable,
          'cy-disabled': !isPrevAvailable,
        })}
        aria-label="Next"
      >
        <IconChevronRight />
      </button>
    </div>
  );
}

export default React.memo(SpreadPreviewNavigation);
