import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { resetSpreadPreviewAction } from 'editor/src/store/editorModules/preview/slice';
import type { ThunkDispatch } from 'editor/src/store/hooks';

const resetSpreadPreviewOperation = () => (dispatch: ThunkDispatch) => {
  batch(() => {
    dispatch(resetSpreadPreviewAction());
  });
};

export default resetSpreadPreviewOperation;
