import * as Sentry from '@sentry/react';

import environment, { EnvEnum } from './environment';
import store from './store';
import { lastActions } from './store/actionLogListener';
import formatDesignDataForLogging from './util/formatDesignDataForLogging';

let pageUnloaded = false;
window.addEventListener('beforeunload', () => {
  pageUnloaded = true;
});
window.addEventListener('pagehide', () => {
  pageUnloaded = true;
});
window.addEventListener('unload', () => {
  pageUnloaded = true;
});

function initSentry(urlParams: URLSearchParams) {
  Sentry.init({
    dsn: 'https://3c3c37746b564cc2ac0de6d61a3cd366@o297899.ingest.sentry.io/5516577',
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: environment.env === EnvEnum.live ? 0.1 : 0,
    release: `design-editor@${process.env.REACT_APP_VERSION}`,
    ignoreErrors: ['TypeError: NetworkError when attempting to fetch resource.', 'TypeError: cancelled'],
    integrations: [
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    beforeSend: (event, hint) => {
      // eslint-disable-next-line no-console
      console.error(hint?.originalException || hint?.syntheticException || event);

      if (pageUnloaded) {
        return null;
      }

      if (!event.extra) {
        event.extra = {
          lastActions,
          designData: formatDesignDataForLogging(store.getState().design.designData),
        };
      }

      return environment.env !== EnvEnum.live ? null : event;
    },
  });

  if (environment.env === EnvEnum.live) {
    Sentry.setUser({
      userId: urlParams.get('userId'),
      storeId: urlParams.get('storeId'),
    });
  }
}

export default initSentry;
