import { batch } from 'editor/src/store/batchedSubscribeEnhancer';
import { setActivePreviewAction, setPreviewStatusAction } from 'editor/src/store/editorModules/preview/slice';
import { PreviewStatus, PreviewType } from 'editor/src/store/editorModules/preview/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { PreviewFormat } from 'editor/src/store/mockup/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const requestSinglePreviewOperation = (spreadIndex: number, isContentBack: boolean) => (dispatch: ThunkDispatch) => {
  batch(() => {
    sendPostMessage('preview.loadSpreadPreview', {
      width: 1200,
      height: 800,
      format: PreviewFormat.Jpg,
      spreadIndex,
      isContentBack,
    });
    dispatch(setPreviewStatusAction(PreviewStatus.LOADING));
    dispatch(setActivePreviewAction(PreviewType.PREVIEW_FLAT));
  });
};

export default requestSinglePreviewOperation;
