import { captureException } from '@sentry/react';

import type { ThunkDispatch } from 'editor/src/store/hooks';
import getECommerceProduct from 'editor/src/store/variants/selector/getECommerceProduct';
import { setECommerceProductIdAction } from 'editor/src/store/variants/slice';

import { RootState } from 'editor/src/store';
import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

import { getSelectedVariant } from 'editor/src/component/DesktopSidebar/TabContents/ProductTabContent/ProductControlContent/ECommerceProductContent/eCommerceVariantUtils';

export const ECOMMERCE_PAGE_COUNT_CONTROL_NAME = 'Page count';

const changeEcommerceVariantPageCountOperation =
  (pageCount: number) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const state = getState();
    const ecommerceProduct = getECommerceProduct(state);
    if (!ecommerceProduct?.variants || !pageCount) {
      return;
    }

    const controlIndex = ecommerceProduct.options.findIndex(
      (option) => option.name === ECOMMERCE_PAGE_COUNT_CONTROL_NAME,
    );
    const selectedVariant = ecommerceProduct.variants.find(
      (variant) => variant.id === ecommerceProduct.selectedVariantId,
    );
    if (!selectedVariant || controlIndex === -1) {
      return;
    }

    const newVariant = getSelectedVariant(
      ecommerceProduct.variants,
      selectedVariant,
      controlIndex,
      pageCount.toString(),
    );

    if (newVariant) {
      dispatch(setECommerceProductIdAction(newVariant.id));
      sendPostMessage('variants.requestECommerceVariant', { gelatoVariantId: newVariant.id });
    } else {
      const error = new Error(`Variant is missed for page count ${pageCount}`);
      const extra = { ecommerceProduct };
      // eslint-disable-next-line no-console
      console.error(error, extra);
      captureException(error, { extra });
    }
  };

export default changeEcommerceVariantPageCountOperation;
