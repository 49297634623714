import './index.scss';
import 'array.prototype.flat/auto';
import 'object.values/auto';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import * as Sentry from '@sentry/react';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { onCLS, onFID, onLCP } from 'web-vitals';

import * as amplitude from './amplitude';
import getDesignDataStats from './amplitude/getDesignDataStats';
import App from './App';
import initSentry from './initSentry';
import store from './store';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
initSentry(urlParams);

if (process.env.NODE_ENV === 'production') {
  amplitude.init(() => getDesignDataStats(store.getState()));

  onCLS((metric) => amplitude.logEvent('web vitals: CLS', metric));
  onFID((metric) => amplitude.logEvent('web vitals: FID', metric));
  onLCP((metric) => amplitude.logEvent('web vitals: LCP', metric));

  ReactDOM.render(
    <Sentry.ErrorBoundary>
      <React.StrictMode>
        <Provider store={store}>
          <Suspense fallback="">
            <App />
          </Suspense>
        </Provider>
      </React.StrictMode>
    </Sentry.ErrorBoundary>,
    document.getElementById('root'),
  );
} else {
  const DevHostApp = React.lazy(() => import('editor/src/devHost/component/App'));
  ReactDOM.render(
    <Sentry.ErrorBoundary>
      <React.StrictMode>
        <Provider store={store}>
          <Suspense fallback="">{urlParams.has('dev-host') ? <DevHostApp /> : <App />}</Suspense>
        </Provider>
      </React.StrictMode>
    </Sentry.ErrorBoundary>,
    document.getElementById('root'),
  );
}
