import { Dimensions } from 'editor/src/store/design/types';
import type { ThunkDispatch } from 'editor/src/store/hooks';
import { RootState } from 'editor/src/store/index';
import getDesignKey from 'editor/src/store/variants/helpers/getDesignKey';
import { UNSELECTED_PAGE_COUNT } from 'editor/src/store/variants/reducer/setPageCountReducer';
import { ProductVariation } from 'editor/src/store/variants/types';

import sendPostMessage from 'editor/src/util/postMessages/sendPostMessage';

const designKeys = new Set<string>();

const checkForMissingTemplatesOperation =
  (matchingVariations: ProductVariation[]) => (dispatch: ThunkDispatch, getState: () => RootState) => {
    const { variationGroups, designTemplates, configuration, product, selectedPageCount, spreadImages } =
      getState().variants;
    if (configuration.singleSelection) {
      return;
    }
    const defaultPageCount = selectedPageCount === UNSELECTED_PAGE_COUNT ? undefined : selectedPageCount;
    const missingDesignData: Array<{
      productUid: string;
      needSpreadImages: boolean;
      dimensions: Dimensions | undefined;
      pageCount: number | undefined;
    }> = [];

    variationGroups.forEach((group) => {
      group.variationsInfo.forEach((variant, i) => {
        const { variation } = variant;
        const designKey = getDesignKey(variation.productUid, { ...variant, pageCount: defaultPageCount });
        if (variation.productUid && (!designTemplates[designKey] || (!spreadImages[designKey] && i === 0))) {
          missingDesignData.push({
            productUid: variation.productUid,
            needSpreadImages: i === 0,
            dimensions: variant.dimensions,
            pageCount: defaultPageCount,
          });
        }
      });
    });

    if (variationGroups.length === 0 && matchingVariations.length && product.externalProductControls.length) {
      const variation = matchingVariations[0];
      if (
        variation.productUid &&
        !designTemplates[
          getDesignKey(variation.productUid, {
            dimensions: undefined,
            pageCount: undefined,
          })
        ]
      ) {
        missingDesignData.push({
          productUid: variation.productUid,
          needSpreadImages: false,
          dimensions: undefined,
          pageCount: defaultPageCount,
        });
      }
    }

    const designsToFetch = missingDesignData.filter(
      (design) => !designKeys.has(getDesignKey(design.productUid, design)),
    );
    if (designsToFetch.length) {
      designsToFetch.forEach((design) => designKeys.add(getDesignKey(design.productUid, design)));
      sendPostMessage('variants.requestDesignTemplates', designsToFetch);

      window.setTimeout(() => {
        designsToFetch.forEach((design) => designKeys.delete(getDesignKey(design.productUid, design)));
      }, 5000);
    }
  };

export default checkForMissingTemplatesOperation;
