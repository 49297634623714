import React, { useEffect } from 'react';

import { isKeyboardUsed } from 'editor/src/component/EditorArea/useKeyboardShorcuts';

import ListItem, { PreviewItem } from './ListItem';

import styles from './index.module.scss';

interface Props {
  items: PreviewItem[];
  activeIndex: number;
  setActiveIndex: (index: number) => void;
}

function FlatPreviewList({ items, activeIndex, setActiveIndex }: Props) {
  const isMoreThanSixItems = items.length > 6;

  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (isKeyboardUsed(event)) {
        return;
      }

      if (event.key === 'ArrowLeft') {
        event.preventDefault();
        setActiveIndex((activeIndex - 1 + items.length) % items.length);
      } else if (event.key === 'ArrowRight') {
        event.preventDefault();
        setActiveIndex((activeIndex + 1) % items.length);
      }
    }

    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [activeIndex, items.length, setActiveIndex]);

  return (
    <div
      className={styles.FlatPreviewList}
      style={isMoreThanSixItems ? { justifyContent: 'flex-start' } : { justifyContent: 'center' }}
    >
      {items.map((item, index) => (
        <ListItem key={index} item={item} active={index === activeIndex} onSelect={setActiveIndex} index={index} />
      ))}
    </div>
  );
}

export default React.memo(FlatPreviewList);
